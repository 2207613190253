import Currency from "currency.js"

export enum ExtraSavingsPeriod {
  DAILY,
  MONTHLY,
  YEARLY,
}

export interface ExtraSavings {
  amount: Currency
  period: ExtraSavingsPeriod
}