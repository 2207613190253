import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#24262b',
      dark: '#24262b',
    },
    secondary: {
      main: '#ffda4b',
    },
    background: {
      default: '#ffe16f',
    },
  },
  typography: {
    fontFamily: '"Roboto Slab","Kanit"',
  },
});

export default theme;

