import {
  AppBar as Bar,
  Container,
  Toolbar,
  Typography,
} from '@mui/material'
import { StackedLineChart } from '@mui/icons-material'

interface AppBarProps {
  /**
   * Label of app bar
   */
  label?: string
}

export const AppBar = ({
  label,
}: AppBarProps) => {
  return (
    <Bar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters sx={{ justifyContent: 'center' }} >
          <StackedLineChart sx={{ display: { xs: 'flex', md: 'flex' }, mr: 1 }}/>
          <Typography
            variant='h6'
            noWrap
            component='a'
            href='#app-bar'
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: '#FFDA4B',
              textDecoration: 'none',
            }}
          >
            { label }
          </Typography>
        </Toolbar>
      </Container>
    </Bar>
  )
}