import Currency from "currency.js"
import { AccountInterface, SavingsResult } from "./account.interface";
import { InterestStep } from "./interest-step.class";
import { Bank } from "../type/bank";
import { ExtraSavings, ExtraSavingsPeriod } from "../type/extra-savings";

export class BaseAccount implements AccountInterface {
  name: string;
  interestIssuePeriodPerYear: string[];
  steps: InterestStep[];
  daysOfYear: Currency = Currency('365', { precision: 4 })
  bank: Bank;
  interestTotal: Currency = Currency('0')
  interestTimes: number = 0
  interestPolicy: string
  isCompound: boolean
  maxPrinciple?: Currency
  minPrinciple?: Currency
  isOnlyTwoDigit?: boolean
  note?: string
  lastUpdate: Date = new Date()
  tags: string[] = []
  minSavingsMonths: number = 0
  logo: string = ''
  private printReport: boolean = false
  registerBefore: Date = new Date('9999-01-01')
  lastExtraSavingsDate: Date = new Date('9999-01-01')

  constructor(
    name: string,
    interestIssuePeriodPerYear: string[],
    steps: InterestStep[],
    bank: Bank,
    interestPolicy: string,
    isCompound: boolean,
    //lastUpdate: Date,
    maxPrinciple?: Currency,
    minPrinciple?: Currency,
    isOnlyTwoDigit?: boolean,
    note?: string,
  ) {
    this.name = name
    this.interestIssuePeriodPerYear = interestIssuePeriodPerYear
    this.bank = bank
    this.interestPolicy = interestPolicy
    this.isCompound = isCompound
    this.isOnlyTwoDigit = isOnlyTwoDigit === false ? false : true
    this.note = note
    // this.lastUpdate = lastUpdate

    if (maxPrinciple) {
      this.maxPrinciple = maxPrinciple
    }

    if (minPrinciple) {
      this.minPrinciple = minPrinciple
    }

    this.steps = []
    let last = Currency("0")
    for (const v of steps) {
      const {to, rate, fromMonth, toMonth} = v
      this.steps.push({from: last, to, rate, fromMonth, toMonth})
      last = v.to
    }
  }

  todayInterest(principle: Currency, today: Date): Currency {
    const interest = this.steps.reduce((interest: Currency, step): Currency => {
      let targetMoney = Currency(0, { precision: 4 })

      if (principle.value >= step.to.value) {
        targetMoney = step.to.subtract(step.from)
      } else if (principle.value > step.from.value) {
        targetMoney = principle.subtract(step.from)
      }

      const stepInterest = Currency(targetMoney.multiply(step.rate).divide(this.daysOfYear))
      return stepInterest.add(interest)
    }, Currency(0, { precision: 4 }))

    return this.clean(Currency(interest.value))
  }

  calculate(principle: Currency, fromDate: Date, toDate: Date, extraSavings?: ExtraSavings): SavingsResult {

    let totalPrinciple = Currency(principle)
    let totalInterest = Currency('0')
    let totalDays = 0

    if (this.minPrinciple && principle.value < this.minPrinciple.value) {
      principle = Currency('0')
    }

    if (this.maxPrinciple && principle.value > this.maxPrinciple.value) {
      principle = Currency(this.maxPrinciple, { precision: 4 })
    }

    const today = new Date(fromDate);
    let interest = Currency(0, { precision: 4 })

    if (this.registerBefore.getTime() < today.getTime()) {
      return {
        accountValue: principle,
        from: fromDate,
        to: toDate,
        totalDays: 0,
        totalInterest: Currency('0'),
        totalPrinciple: principle,
      }
    }

    while (today.getTime() <= toDate.getTime()) {

      if (
        extraSavings
        && (
          (extraSavings.period === ExtraSavingsPeriod.DAILY)
          || (extraSavings.period === ExtraSavingsPeriod.MONTHLY && today.getDate() === 1)
        )
        && today.getTime() <= this.lastExtraSavingsDate.getTime()
      ) {
        principle = principle.add(extraSavings.amount)
        totalPrinciple = totalPrinciple.add(extraSavings.amount)
        if (this.maxPrinciple && principle.value >= this.maxPrinciple.value) {
          principle = Currency(this.maxPrinciple, { precision: 4 })
          totalPrinciple = Currency(this.maxPrinciple, { precision: 4 })
        }
      }

      const todayInterest = this.todayInterest(principle, toDate)
      interest = interest.add(todayInterest)
      totalInterest = totalInterest.add(todayInterest)
      this.interestTotal = this.interestTotal.add(todayInterest)

      const isIssueDate = this.interestIssuePeriodPerYear.reduce((isTheDay, issueDay) => {
        const issueDate = new Date(issueDay);
        if (today.getMonth() === issueDate.getMonth() && today.getDate() === issueDate.getDate()) {
          isTheDay = isTheDay || true
        } else {
          isTheDay = isTheDay || false
        }

        return isTheDay
      }, false)

      if (isIssueDate) {
        this.interestTimes++
        if (this.isCompound) {
          principle = principle.add(interest)
          interest = Currency(0, { precision: 4 })
        }
      }
      today.setDate(today.getDate() + 1)
      totalDays++
    }

    return {
      totalPrinciple,
      totalInterest,
      accountValue: totalPrinciple.add(totalInterest),
      totalDays,
      from: fromDate,
      to: toDate,
      extraSavings,
    }
  }

  setIsCompound(isCompound: boolean): AccountInterface {
    this.isCompound = isCompound
    return this
  }

  clean(number: Currency): Currency {
    if (!this.isOnlyTwoDigit) {
      return number
    }

    const cleanNumber = Currency(Math.floor(number.multiply(100).value), {precision: 2}).divide(100)
    return cleanNumber
  }
}