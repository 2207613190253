import React, { useState } from 'react'
import Currency from 'currency.js'
import {
  Avatar, Backdrop, Button, CardHeader, Container, CssBaseline, FormControl, Grid,
  InputLabel,
  MenuItem,
  Paper, Select, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField, Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { ExtraSavingsPeriod } from './type/extra-savings';
import { AccountSearchResult } from './type/accrount-search-result';
import { getAllSavingsAccounts } from './account/all-account';
import { AppBarComponent } from './components/app-bar/app-bar.component';
import { MMTextField } from './components/mm-text-field/mm-text-field.component';
import { FrontPage } from './components/font-page/font-page.component';
import { AppBar } from './stories/manager-money/appbar/AppBar'

function App() {

  const accounts: AccountSearchResult[] = []

  const [principleVal, setPrincipleVal] = useState(Currency('0'))
  const [extraVal, setExtraVal] = useState(Currency('0'))
  const [accountList, setAccountList] = useState(accounts)
  const [isLoading, setIsLoading] = useState(false)
  const [savingsDuration, setSavingsDuration] = useState(6)
  const [dateFrom, setDateFrom] = useState(new Date())
  const [dateTo, setDateTo] = useState(new Date())

  const onPrincipleChange = (e: any) => {
    const val = e.target.value
    let num = Currency('0')
    if (!isNaN(val)) {
      num = Currency(val)
    }

    setPrincipleVal(num)
  }

  const onExtraChange = (e: any) => {
    const val = e.target.value
    let num = Currency('0')
    if (!isNaN(val)) {
      num = Currency(val)
    }

    setExtraVal(num)
  }

  const durationChange = (e: any) => {
    const val = e.target.value
    let num = 0
    if (!isNaN(val)) {
      num = Number(val)
    }

    setSavingsDuration(num)
  }

  const showLoading = () => {
    setIsLoading(true)
  }

  const hideLoading = () => {
    setIsLoading(false)
  }

  const calculateClick = () => {
    showLoading()

    const interests: AccountSearchResult[] = []

    if (principleVal.value === 0 && extraVal.value === 0) {
      setAccountList(interests)
      hideLoading()
      return
    }


    const now = new Date()
    const masterFrom = new Date(now.getFullYear(), now.getMonth(), 1)
    const masterTo = addMonths(masterFrom, savingsDuration)
    setDateFrom(masterFrom)
    setDateTo(masterTo)

    for (const a of getAllSavingsAccounts()) {
      const from = new Date(masterFrom)
      const p = Currency(principleVal)
      const res = a.calculate(p, from, masterTo, extra)

      interests.push({
        total: res,
        account: a,
        avartar: <Avatar
          alt={a.bank.shortNameEn}
          src={a.logo || a.bank.logoURL}
          sx={{ width: 40, height: 40, bgcolor: a.bank.color, padding: 0.9 }}
          variant='circular'
        />
      })
    }
    const sorted = interests.sort((a, b) => b.total.totalInterest.value - a.total.totalInterest.value)
    setAccountList(sorted)
    hideLoading()
  }

  const addMonths = (fromDate: Date, months: number): Date => {
    let d = fromDate.getTime()
    const date = new Date(d)
    date.setMonth(date.getMonth() + +months)
    if (date.getDate() !== d) {
      date.setDate(0)
    }
    return date;
  }

  let rank = 1

  // const fromStr = '2023-01-01'
  const THB = (value: Currency) => Currency(value, { symbol: '฿', precision: 2 });
  const extra = { amount: extraVal, period: ExtraSavingsPeriod.MONTHLY }

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          paddingTop: '5rem',
          backgroundColor: theme.palette.secondary.main,
        }}
      >
        <AppBar label='MANAGER.MONEY' />
        <FrontPage />
      </Container>
    </ThemeProvider>
  );
}

export default App;

