import Currency from "currency.js"
import { BaseAccount } from "../base.account"
import { InterestStep } from "../interest-step.class"
import { Bank } from "../../type/bank"
import { TagFixedAccount, TagKTB, TagMonthly, TagNonFlexible, TagTaxExempt } from "../../type/tag"
import { ExtraSavings } from "../../type/extra-savings"
import { SavingsResult } from "../account.interface"
import { getMonthsBetweenDates } from "../../helper/date-different"

export class KTBZeroTaxMaxSavingsAccount extends BaseAccount {
  constructor() {
    const name = "ZERO TAX MAX"
    const interestIssuePeriodPerYear = [
      "2023-06-30",
      "2023-12-31",
    ]

    const steps: InterestStep[] = [
      { from: Currency("0"), to: Currency("999999999999"), rate: Currency("0.0230", { precision: 4 }) },
    ]

    super(name, interestIssuePeriodPerYear, steps, Bank.KrungthaiBank, 'ปีละ 2 ครั้ง', true)
    this.tags = [TagKTB, TagTaxExempt, TagFixedAccount, TagMonthly, TagNonFlexible]
    this.minSavingsMonths = 24
  }

  calculate(principle: Currency, fromDate: Date, toDate: Date, extraSavings?: ExtraSavings): SavingsResult {
    const monthDiff = getMonthsBetweenDates(fromDate, toDate)

    if (monthDiff < this.minSavingsMonths - 1 || !extraSavings || extraSavings.amount.value <= 1_000) {
      return {
        from: fromDate,
        to: toDate,
        totalDays: 0,
        accountValue: Currency(principle),
        totalInterest: Currency('0'),
        totalPrinciple: Currency(principle),
      }
    }

    return super.calculate(principle, fromDate, toDate, extraSavings)
  }
}