import React from 'react'
import { TextFieldProps } from '@mui/material/TextField'
import { TextField } from '@mui/material'

export const MMTextField = (props: TextFieldProps) => {
  return (
  <TextField
    {...props}
    sx={{
      margin: '5px',
      width: '100%',
      '& fieldset': {
        paddingLeft: (theme) => theme.spacing(2.5),
        borderRadius: '40px',
        backgroundColor: 'white',
      },
    }}
  >
    {props.children}
  </TextField>
  )
}