export class Bank {
  nameEn: string
  nameTh: string
  shortNameEn: string
  shortNameTh: string
  logoURL: string
  color?: string

  private constructor(nameEn: string, nameTh: string, sNameEn: string, sNameTh: string, logoURL: string, color?: string) {
    this.nameEn = nameEn
    this.nameTh = nameTh
    this.shortNameEn = sNameEn
    this.shortNameTh = sNameTh
    this.logoURL = logoURL
    this.color = color
  }

  public static BangkokBank: Bank = {
    nameEn: 'Bangkok Bank',
    nameTh: '',
    shortNameEn: 'BBL',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/bbl.svg',
    color: '#1e4598'
  }

  public static KasikornBank: Bank = {
    nameEn: 'Kasikorn Bank',
    nameTh: '',
    shortNameEn: 'KBANK',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/kbank.svg',
    color: '#138f2d'
  }

  public static RoyalBankofScotland: Bank = {
    nameEn: 'Royal Bank of Scotland',
    nameTh: '',
    shortNameEn: 'RBS',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/rbs.svg',
    color: '#032952'
  }

  public static KrungthaiBank: Bank = {
    nameEn: 'Krungthai Bank',
    nameTh: '',
    shortNameEn: 'KTB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/ktb.svg',
    color: '#1ba5e1'
  }

  public static JPMorgan: Bank = {
    nameEn: 'J.P. Morgan',
    nameTh: '',
    shortNameEn: 'JPM',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/jpm.svg',
    color: '#321c10'
  }

  public static BankofTokyoMitsubishiUFJ: Bank = {
    nameEn: 'Bank of Tokyo-Mitsubishi UFJ',
    nameTh: '',
    shortNameEn: 'MUFG',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/mufg.svg',
    color: '#d61323'
  }

  public static TMBBank: Bank = {
    nameEn: 'TMB Bank',
    nameTh: '',
    shortNameEn: 'TMB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/tmb.svg',
    color: '#1279be'
  }

  public static SiamCommercialBank: Bank = {
    nameEn: 'Siam Commercial Bank',
    nameTh: '',
    shortNameEn: 'SCB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/scb.svg',
    color: '#4e2e7f'
  }

  public static Citibank: Bank = {
    nameEn: 'Citibank',
    nameTh: '',
    shortNameEn: 'CITI',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/citi.svg',
    color: '#1583c7'
  }

  public static SumitomoMitsuiBankingCorporation: Bank = {
    nameEn: 'Sumitomo Mitsui Banking Corporation',
    nameTh: '',
    shortNameEn: 'SMBC',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/smbc.svg',
    color: '#a0d235'
  }

  public static StandardCharteredThai: Bank = {
    nameEn: 'Standard Chartered (Thai)',
    nameTh: '',
    shortNameEn: 'SC',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/sc.svg',
    color: '#0f6ea1'
  }

  public static CIMBThaiBank: Bank = {
    nameEn: 'CIMB Thai Bank',
    nameTh: '',
    shortNameEn: 'CIMB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/cimb.svg',
    color: '#FF0000'
  }

  public static UnitedOverseasBankThai: Bank = {
    nameEn: 'United Overseas Bank (Thai)',
    nameTh: '',
    shortNameEn: 'UOB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/uob.svg',
    color: '#0b3979'
  }

  public static BankofAyudhyaKrungsri: Bank = {
    nameEn: 'Bank of Ayudhya (Krungsri)',
    nameTh: '',
    shortNameEn: 'BAY',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/bay.svg',
    color: '#fec43b'
  }

  public static MegaInternationalCommercialBank: Bank = {
    nameEn: 'Mega International Commercial Bank',
    nameTh: '',
    shortNameEn: 'MEGA',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/mega.svg',
    color: '#815e3b'
  }

  public static BankofAmerica: Bank = {
    nameEn: 'Bank of America',
    nameTh: '',
    shortNameEn: 'BOA',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/boa.svg',
    color: '#e11e3c'
  }

  public static CréditAgricole: Bank = {
    nameEn: 'Crédit Agricole',
    nameTh: '',
    shortNameEn: 'CACIB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/cacib.svg',
    color: '#0e765b'
  }

  public static GovernmentSavingsBank: Bank = {
    nameEn: 'Government Savings Bank',
    nameTh: '',
    shortNameEn: 'GSB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/gsb.svg',
    color: '#eb198d'
  }

  public static HongkongandShanghaiBankingCorporation: Bank = {
    nameEn: 'Hongkong and Shanghai Banking Corporation',
    nameTh: '',
    shortNameEn: 'HSBC',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/hsbc.svg',
    color: '#fd0d1b'
  }

  public static DeutscheBank: Bank = {
    nameEn: 'Deutsche Bank',
    nameTh: '',
    shortNameEn: 'DB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/db.svg',
    color: '#0522a5'
  }

  public static GovernmentHousingBank: Bank = {
    nameEn: 'Government Housing Bank',
    nameTh: '',
    shortNameEn: 'GHB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/ghb.svg',
    color: '#f57d23'
  }

  public static BankforAgricultureandAgriculturalCooperatives: Bank = {
    nameEn: 'Bank for Agriculture and Agricultural Cooperatives',
    nameTh: '',
    shortNameEn: 'BAAC',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/baac.svg',
    color: '#4b9b1d'
  }

  public static MizuhoBank: Bank = {
    nameEn: 'Mizuho Bank',
    nameTh: '',
    shortNameEn: 'MB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/mb.svg',
    color: '#150b78'
  }

  public static ThanachartBank: Bank = {
    nameEn: 'Thanachart Bank',
    nameTh: '',
    shortNameEn: 'TBANK',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/tbank.svg',
    color: '#fc4f1f'
  }

  public static BNPParibas: Bank = {
    nameEn: 'BNP Paribas',
    nameTh: '',
    shortNameEn: 'BNP',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/bnp.svg',
    color: '#14925e'
  }

  public static IslamicBankofThailand: Bank = {
    nameEn: 'Islamic Bank of Thailand',
    nameTh: '',
    shortNameEn: 'IBANK',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/ibank.svg',
    color: '#184615'
  }

  public static TiscoBank: Bank = {
    nameEn: 'Tisco Bank',
    nameTh: '',
    shortNameEn: 'TISCO',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/tisco.svg',
    color: '#12549f'
  }

  public static KiatnakinBank: Bank = {
    nameEn: 'Kiatnakin Bank',
    nameTh: '',
    shortNameEn: 'KKP',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/kk.svg',
    color: '#635f98'
  }

  public static IndustrialandCommercialBankofChinaThai: Bank = {
    nameEn: 'Industrial and Commercial Bank of China (Thai)',
    nameTh: '',
    shortNameEn: 'ICBC',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/icbc.svg',
    color: '#c50f1c'
  }

  public static ThaiCreditRetailBank: Bank = {
    nameEn: 'Thai Credit Retail Bank',
    nameTh: '',
    shortNameEn: 'TCRB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/tcrb.svg',
    color: '#0a4ab3'
  }

  public static LandandHousesBank: Bank = {
    nameEn: 'Land and Houses Bank',
    nameTh: '',
    shortNameEn: 'LHB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/lhb.svg',
    color: '#6d6e71'
  }

  public static TMBThanachartBank: Bank = {
    nameEn: 'TMBThanachart Bank',
    nameTh: '',
    shortNameEn: 'TTB',
    shortNameTh: '',
    logoURL: 'https://raw.githubusercontent.com/omise/banks-logo/master/th/ttb.svg',
    color: '#0050f0'
  }
}