import React from 'react';
import './button.css';

interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Optional width
   */
  width?: 'flex' | 'full' | 'half'
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  primary = false,
  size = 'medium',
  backgroundColor,
  label,
  width = 'flex',
  ...props
}: ButtonProps) => {
  const mode = primary ? 'storybook-button--primary' : 'storybook-button--secondary';

  const classes: string[] = [
    'storybook-button',
    `storybook-button--${size}`,
    mode,
  ]

  if (width === 'full') {
    classes.push(`storybook-button--width-full`)
  } else if (width === 'half') {
    classes.push(`storybook-button--width-half`)
  }
  
  return (
    <button
      type="button"
      className={classes.join(' ')}
      style={{ backgroundColor }}
      {...props}
    >
      {label}
    </button>
  );
};
