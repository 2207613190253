import {
  Typography, Grid,
} from '@mui/material';
import { MMTextField } from '../mm-text-field/mm-text-field.component';
import { Button } from '../../stories/manager-money/button/Button'

export const FrontPage = () => {

  const calculateClick = () => { }

  return (
    <Grid
      container
    >
      <Grid
        item
        container
        justifyContent='center'
        xs={12}
      >
        <Typography
          variant='h4'
          textAlign='center'
        >
          คำนวนดอกเบี้ยเงินฝาก
        </Typography>
        <Typography variant='h4' textAlign='center'>บัญชีธนาคารไหนดีที่สุด</Typography>
        <Typography variant='body1' textAlign='center'>Calculate the best solution for your savings</Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sx={{ paddingTop: 3 }}
      >
        <MMTextField
          label='เงินต้น/Deposit'
          variant='outlined'
        />
        <MMTextField
          label='ฝากเพิ่มต่อเดือน/Monthly Top-Up'
          variant='outlined'
        />
        <MMTextField
          label='ระยะเวลาในการฝาก/Savings Length'
          variant='outlined'
        />

        <Button
          label='คำนวน'
          primary
          size='large'
          width='full'
        />
      </Grid>
    </Grid>
  )
}